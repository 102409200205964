import {getItem, removeItem, setItem} from './session-storage'
import {requestUri} from './analytics-overrides'
import {sendStats} from './stats'

export const SOFT_NAV_STATE = Object.freeze({
  INITIAL: 'soft-nav:initial',
  SUCCESS: 'soft-nav:success',
  ERROR: 'soft-nav:error',
  START: 'soft-nav:start',
  END: 'soft-nav:end'
})
const SOFT_NAV_FAIL = 'soft-navigation-fail'
const SOFT_NAV_REFERRER = 'soft-navigation-referrer'
const SOFT_NAV_MARK = 'soft-navigation-marker'
const DEFAULT_SOFT_NAV_ERROR_REASON = 'reload'

export function inSoftNavigation() {
  return getItem(SOFT_NAV_MARK) === '1'
}

export function hasSoftNavFailure() {
  return Boolean(getSoftNavFailReason())
}

export function startSoftNav() {
  performance.mark(SOFT_NAV_MARK)
  setItem(SOFT_NAV_MARK, '1')
  setItem(SOFT_NAV_REFERRER, requestUri() || window.location.href)
}

export function endSoftNav() {
  setItem(SOFT_NAV_MARK, '0')
}

function clearSoftNav() {
  setItem(SOFT_NAV_MARK, '0')
  removeItem(SOFT_NAV_REFERRER)
  removeItem(SOFT_NAV_FAIL)
}

export function setSoftNavFailReason(reason: string) {
  setItem(SOFT_NAV_FAIL, reason || DEFAULT_SOFT_NAV_ERROR_REASON)
}

function getSoftNavFailReason() {
  return getItem(SOFT_NAV_FAIL)
}

let visitCount = 0
export function softNavSucceeded() {
  visitCount += 1
  document.dispatchEvent(new CustomEvent(SOFT_NAV_STATE.SUCCESS, {detail: visitCount}))
}

export function softNavFailed() {
  const reason = getSoftNavFailReason() || DEFAULT_SOFT_NAV_ERROR_REASON
  document.dispatchEvent(new CustomEvent(SOFT_NAV_STATE.ERROR, {detail: reason}))
  visitCount = 0
  clearSoftNav()

  sendStats({
    turboFailureReason: reason
  })
}

export function softNavInitial() {
  document.dispatchEvent(new CustomEvent(SOFT_NAV_STATE.INITIAL))
  visitCount = 0
  clearSoftNav()
}

export function getSoftNavReferrer() {
  return getItem(SOFT_NAV_REFERRER) || document.referrer
}

export function getDurationSinceLastSoftNav() {
  if (performance.getEntriesByName(SOFT_NAV_MARK).length === 0) {
    return 0
  }
  return performance.measure(SOFT_NAV_MARK, SOFT_NAV_MARK).duration
}

export function beginProgressBar() {
  document.dispatchEvent(new Event(SOFT_NAV_STATE.START))
}

export function completeProgressBar() {
  document.dispatchEvent(new Event(SOFT_NAV_STATE.END))
}
